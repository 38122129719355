import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"

import Layout2 from "../components/layout"
import GraphQLErrorList from "../components/graph-ql-error-list"

import HeroText from "../components/modules/heroText"
import FeaturedProjectsGrid from "../components/modules/featuredProjectsGrid"
import HeroProject from "../components/modules/heroProject"
import SplitHero from "../components/modules/splitHero"
import PageRte from "../components/modules/pageRte"
import Wrapper from "../components/modules/wrapper"

//Styling

const ServicesWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 6rem;

  h2 {
    grid-row: 1;
    grid-column: 1 / -1;
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 1rem;
  }
`

const AboutWrapper = styled.div`
  margin-bottom: 6rem;
`

export const query = graphql`
  query AboutQuery {
    sanityPages(title: { eq: "About" }) {
      title
      _rawPModules(resolveReferences: { maxDepth: 5 })
    }
  }
`

// My About Page Starts Here

const AboutPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout2>
        <GraphQLErrorList errors={errors} />
      </Layout2>
    )
  }

  const page = data.sanityPages

  const content = (page._rawPModules || [])
    .filter(c => !c.disabled)
    .map((c, i) => {
      let el = null
      switch (c._type) {
        case "heroText":
          el = <HeroText key={c._key} {...c} />
          break
        case "featuredProjectsGrid":
          el = <FeaturedProjectsGrid key={c._key} {...c} />
          break
        case "heroProject":
          el = <HeroProject key={c._key} {...c} />
          break
        case "splitHero":
          el = <SplitHero key={c._key} {...c} />
          break
        case "pageRte":
          el = <PageRte key={c._key} {...c} />
          break
        default:
          el = null
      }
      return el
    })

  return (
    <Layout2>
      <AboutWrapper>{content}</AboutWrapper>

      <Wrapper>
        <ServicesWrapper>
          <h2>Our Services</h2>
          <ul>
            <li>
              <h3>Strategy</h3>
            </li>
            <li>Discovery and research</li>
            <li>User Experience</li>
            <li>Brand Strategy / Architecture</li>
            <li>Content Strategy</li>
            <li>Naming</li>
            <li>Marketing Campaigns</li>
            <li>Social Campaigns</li>
          </ul>
          <ul>
            <li>
              <h3>Design</h3>
            </li>
            <li>Brand Development</li>
            <li>Rebranding</li>
            <li>Identity Systems</li>
            <li>Brand Style Guides</li>
            <li>Messaging</li>
            <li>Print design</li>
            <li>Environmental Design</li>
            <li>Packaging</li>
          </ul>
          <ul>
            <li>
              <h3>Development</h3>
            </li>
            <li>Website Design</li>
            <li>Website Development</li>
            <li>UI / UX</li>
            <li>Information Architecture</li>
            <li>Wireframing</li>
            <li>Prototypes</li>
            <li>CMS Development</li>
            <li>E-commerce</li>
          </ul>
          <ul>
            <li>
              <h3>Services</h3>
            </li>
            <li>Supply-chain</li>
            <li>Production</li>
            <li>Shipping</li>
            <li>Fulfillment</li>
            <li>Illustration</li>
            <li>Video</li>
            <li>Photography</li>
          </ul>
        </ServicesWrapper>
      </Wrapper>
    </Layout2>
  )
}

export default AboutPage
